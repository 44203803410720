<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Surveys</h3>
      <router-link
        :to="`/reputation/review-flow/new`"
        class="btn btn-outline btn-sm ms-auto"
      >
        New Survey
      </router-link>
    </div>
    <div v-if="loaded">
      <div class="row" v-if="reviewFlows.length > 0">
        <div class="col-8">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="flow in reviewFlows" :key="flow.id">
                  <td>
                    <router-link :to="`/reputation/review-flow/${flow.id}`">
                      {{ flow.name }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-4">
          <div class="empty">
            <div class="empty-img">
              <img
                src="@/assets/illustrations/undraw_High_five.svg"
                alt=""
                height="128"
              />
            </div>

            <p class="empty-title">Let's raise your Rep</p>

            <p class="empty-subtitle text-muted">
              Start generating customer reviews using your surveys. Show off how
              great your customers think you are.
            </p>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <div class="empty-img">
          <img
            src="@/assets/illustrations/undraw_for_review.svg"
            alt=""
            height="128"
          />
        </div>
        <p class="empty-title">No surveys yet</p>
        <p class="empty-subtitle text-muted">
          Create a suvey to start building your brand's reputation and
          collecting customer praise.
        </p>
        <div class="empty-action">
          <router-link
            :to="`/reputation/review-flow/new`"
            class="btn btn-primary"
          >
            Create a survey
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";

export default {
  name: "reputation.reviewflows",
  data: () => {
    return {
      reviewFlows: [],
      loaded: false,
    };
  },
  async mounted() {
    this.reviewFlows = await ReputationManagementService.getReviewFlows(
      this.$auth.activeCompany
    );
    this.loaded = true;
  },
};
</script>
